.App, .AppMobile {
  position: relative;
  text-align: center;
}

.Img, .MobileImg {
  position: fixed;
  top: 8vw;
  left: 2.4vw;
  z-index: -10;
  min-height: 100vw;
  max-height: 100vw;
}

.Img {
  min-height: 125vw;
  max-height: 125vw;
}

.MobileImg {
  min-height: 200vw;
  max-height: 200vw;
}