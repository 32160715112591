.elementBody {
}

.elementLargeBlock {
    position: relative;
    justify-self: center;

    margin: 6vw 0 2vw 0;

    width: 80vw;
    height: 40vw;
    background-color: #eaf7ff;
    border: 0.5vw solid #f79152;
    filter: drop-shadow(2vw 2vw 2vw #4f9bae)
}

.elementHeader {
    margin: 2vw 0 0 2vw;

    font-family: "BlackSteel";
    font-size: 3.8vw;
    text-align: left;
}

.elementBody {
    margin: 2vw 0 0 2vw;

    font-family: "ElectromagneticLungs";
    font-size: 3vw;
    text-align: left;
}