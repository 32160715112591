.eleve {
    min-width: 100vw;
    min-height: 100vh;

    background-color: #31121f;
}

.moreDetails {
    color: white;
    font-size: 6vw;

    margin-top: 0;
    padding-top: 2vw;
}

.eleveImage {
    width: 100vw;
}