.byteText {
    width: 18vw;
    padding-top: 2.5vw;
    padding-left: 2.4vw;
  }

  .header{
    width: 100%;
    z-index: 10;
    top: 8vw;
    left: 2.4vw;
    display: flex;
    flex-direction: horizontal;
  }

  .navBar {
    margin-left: 2vw;
    border-radius: 30vw;
    background-color: #2a9db0;
    display: grid;
    grid-auto-flow: column;
    margin-top: 2.5vw;
    padding-right: 2vw;
    padding-left: 2vw;
  }

  .tab {
    margin-right: 0.75vw;
    margin-left: 0.75vw;
    height: min-content;
    margin-top: 1vw;
    padding-top: 0px;
    font-family: "Kimberley";
    color: black;

    width: 14vw;
    display:inline-block;
    background-color: white;
    border-radius: 15vw;
    border: none;
    font-size: 2vw;
    transition: background-color 1s linear, width 1s linear;
  }

  .tab:hover {
    color: black;
    background-color: #ff934F;
  }
  
  .activetab {
    margin-right: 5px;
    margin-left: 5px;
    height: fit-content;
    margin-top: 1vw;
    padding-top: 0px;
    font-family: "Kimberley";
    width: 24vw;
    background-color: white;
    color: black;
    border-radius: 15vw;
    border: none;
    font-size: 2vw;
    transition: background-color 1s linear, width 1s linear;
  }

  .activetab:hover {
    color: black;
    background-color: #ff934F;
  }